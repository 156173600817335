<template>
  <div class="auth-wrapper auth-v1 px-2 bg-inner-img" v-bind:style="{ backgroundImage: 'url(' + sideImg + ')' }">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
            <img :src="logoImg" class="brand-logo-img">
          <!-- logo -->
          <!-- <vuexy-logo /> -->

          <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2> -->
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <!-- form -->
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
        <ValidationObserver v-slot="{ handleSubmit}">
            <b-form @submit.prevent="handleSubmit(forgotPassword)">
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </ValidationObserver>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-overlay>
      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>
<style scoped>
  .bg-inner-img {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .brand-logo-img {
    max-width: 80px;
  }
</style>
<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BOverlay, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import AuthService from '@/services/auth.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BOverlay
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/banner-1.jpg'),
      logoImg: require('@/assets/images/pages/anclademia-favi.png'),
      showOverlay: false
    }
  },
  methods: {
    forgotPassword() {
      this.showOverlay = true;
      AuthService.forgotPassword(this.userEmail).then(response => {
        this.showOverlay = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email Sent',
            icon: 'EditIcon',
            variant: 'success',
          },
        })    
                
        }).catch(error => {
            this.showOverlay = false;
            console.log(error)
        });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
